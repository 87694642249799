<template>
  <v-container>
    <tasks-list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      @details="previewFn"
      @reload="loadTasks"
      @new="newFn"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import TasksList from "@/components/lists/TasksList.vue";
import snackbar from "@/utils/snackbar.js";
export default {
  components: {
    TasksList,
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "left",
        value: "name",
      },
      {
        text: "Comment",
        align: "left",
        value: "description",
      },
      {
        text: "Boat",
        align: "left",
        value: "boat_name",
      },
      {
        text: "Assigned to",
        align: "left",
        value: "assignee",
      },
      {
        text: "Due date",
        align: "left",
        value: "due_date",
      },
      {
        text: "Status",
        align: "left",
        value: "status",
      },
    ],
    data: [],
    sort: "name",
    title: "Tasks",
    deleteDialog: false,
    boat: null,
    boatName: null,
    loading: true,
    response: null,
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.loadTasks(this.$route.query);
    } else {
      this.loadTasks();
    }
  },
  methods: {
    loadTasks(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      api.getTaskList(params).then((resp) => {
        this.response = resp.data;
        this.data = resp.data.results;
        this.data.forEach((element) => {
          element.priority = Number(element.priority);
          element.assignee = "";
          element.boat_name = "";
          element.checklists.forEach((checklist) => {
            if (
              !element.assignee.includes(checklist.assignee.email) &&
              checklist.assignee.email
            ) {
              element.assignee += checklist.assignee.email + ", ";
            }
            if (
              checklist.crm_boat !== null &&
              checklist.crm_boat.boat_name !== null &&
              !element.boat_name.includes(checklist.crm_boat.boat_name)
            ) {
              element.boat_name += checklist.crm_boat.boat_name + ", ";
            }
          });
          if (element.assignee) {
            element.assignee = element.assignee.slice(0, -2);
          }
          if (element.boat_name) {
            element.boat_name = element.boat_name.slice(0, -2);
          }
        });

        // let mapStatuses = this.data.map((element) => element.status);
        // let lastNotStartedIndex = mapStatuses.lastIndexOf("NOT_STARTED");
        // let lastInProgressIndex = mapStatuses.lastIndexOf("IN_PROGRESS");

        // if (lastNotStartedIndex < 23) {
        //   this.data.splice(lastNotStartedIndex + 1, 0, {
        //     type: "special",
        //     name: "In progress",
        //   });
        // }

        this.loading = false;
      });
    },

    previewFn(task) {
      this.$router.push({
        name: "TasksDetails",
        params: { id: task.uuid },
      });
    },
    newFn(task) {
      this.$router.push({
        name: "TasksNew",
      });
    },
  },
};
</script>
