<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      :items-per-page="24"
      :class="{ 'elevation-1': !bookings, 'my-10': !bookings }"
      class="text-overflow"
      @click:row="previewItem"
    >
      <!-- style="cursor:pointer" -->
      <template v-slot:top v-if="!bookings">
        <v-toolbar flat height="70">
          <!-- <v-row class="mt-4"> -->
          <v-col cols="4" class="pl-0">
            <v-row justify="start" class="ml-1">
              <v-toolbar-title class="headline" :data-cy="title">
                <span>
                  {{ title }}
                </span>
              </v-toolbar-title>
            </v-row>
          </v-col>
          <v-col cols="4" class="mt-4">
            <v-row justify="center">
              <v-text-field
                class="mx-4"
                v-model="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch"
                color="primary"
              />
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row justify="end" class="mr-1">
              <v-btn color="primary" dark text @click="newItem()"
                >Add new
              </v-btn>
            </v-row>
          </v-col>
          <!-- </v-row> -->
        </v-toolbar>
      </template>
      <template v-slot:item.uuid="{ item }">
        <span>{{ item.uuid.slice(28, item.uuid.length) }} </span>
      </template>
      <!-- <template v-slot:item.assignee="{ item }">
        <span>{{ item.assignee.email }} </span>
      </template> -->
      <template v-slot:item.source="{ item }">
        <span class="text-capitalize">{{ item.source.toLowerCase() }} </span>
      </template>
      <template v-slot:item.description="{ item }">
        <span v-if="item.description !== null && item.description.length > 50"
          >{{ item.description.slice(0, 50).concat("...") }}
        </span>
        <span v-else>
          {{ item.description }}
        </span>
      </template>
      <template v-slot:item.due_date="{ item }">
        <span class="text-capitalize">{{ formatDate(item.due_date) }} </span>
      </template>
      <template v-slot:item.status="{ item }">
        <div v-if="item.status === 'COMPLETED'">
          <v-icon color="primary">{{
            getPercentageOfCompletionIcon(item).icon
          }}</v-icon>
        </div>
        <div v-if="item.status !== 'COMPLETED'">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon color="primary">{{
                  getPercentageOfCompletionIcon(item).icon
                }}</v-icon>
              </span>
            </template>
            <span v-if="getPercentageOfCompletion(item) !== null">
              <span v-if="item.status === 'IN_PROGRESS'">
                In Progress:
                {{ getPercentageOfCompletion(item) }} %
              </span>
              <span v-else>
                Completed:
                {{ getPercentageOfCompletion(item) }} %
              </span>
            </span>
            <span v-else>
              Cannot be calculated
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.crm_boat="{ item }">
        <span v-if="item.crm_boat"
          >{{ item.crm_boat.model.brand_name }}
          {{ item.crm_boat.model.model }}</span
        >
      </template>
      <template v-slot:item.priority="{ item }">
        <v-rating v-model="item.priority" length="4" readonly>
          <template v-slot:item="props">
            <v-icon
              :color="props.isFilled ? 'primary' : ''"
              v-text="`mdi-numeric-${props.index + 1}-box`"
            ></v-icon>
          </template>
        </v-rating>
      </template>
    </v-data-table>
    <v-pagination
      v-if="!bookings"
      v-model="currentPage"
      :length="numberOfPages"
      :total-visible="7"
      color="primary"
    />
  </div>
</template>

<script>
import debounce from "@/plugins/debounce";

export default {
  props: {
    title: String,
    headers: Array,
    data: Array,
    sort: String,
    loading: Boolean,
    response: Object,
    bookings: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  data: () => ({
    search: "",
    page: 1,
    filterSelected: "All",
    filterOptions: [
      {
        name: "All",
        value: "All",
      },
      {
        name: "Completed",
        value: "COMPLETED",
      },
      {
        name: "My Tasks",
        value: "MY_TASKS",
      },
    ],
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.search = this.$route.query.search;
      this.currentPage = Number(this.$route.query.page);
    }
  },

  computed: {
    formTitle() {
      return this.title.substring(0, this.title.length - 1);
    },
    numberOfPages() {
      if (!this.response) {
        return 0;
      }
      return parseInt(Math.ceil(this.response.count / 24));
    },
    currentPage: {
      get: function() {
        return this.page;
      },
      set: function(value) {
        this.page = value;
        this.$emit("reload", { search: this.search, page: value });
        this.$router.replace({
          name: this.$route.name,
          query: {
            page: this.page,
            search: this.search,
          },
        });
      },
    },
  },
  methods: {
    getPercentageOfCompletionIcon(item) {
      const iconLookupTableNotStarted = {
        icon: "mdi-circle-outline",
        value: 0,
      };

      const iconLookupTableInProgress = [
        { icon: "mdi-circle-slice-2", value: 25 },
        { icon: "mdi-circle-slice-4", value: 50 },
        { icon: "mdi-circle-slice-6", value: 75 },
      ];

      const iconLookupTableCompleted = {
        icon: "mdi-circle-slice-8",
        value: 100,
      };

      const percentage = this.getPercentageOfCompletion(item);

      if (isNaN(percentage)) {
        if (item.status === "NOT_STARTED") return iconLookupTableNotStarted;
        if (item.status === "IN_PROGRESS") return iconLookupTableInProgress[0];
        if (item.status === "COMPLETED") return iconLookupTableCompleted;
      }

      if (item.status === "NOT_STARTED") {
        return iconLookupTableNotStarted;
      }

      if (item.status === "IN_PROGRESS") {
        return iconLookupTableInProgress.reduce((previous, current) => {
          if (previous.value < percentage && current.value >= percentage) {
            return current;
          } else {
            return previous;
          }
        }, iconLookupTableInProgress[0]);
      }

      if (item.status === "COMPLETED") {
        return iconLookupTableCompleted;
      }
    },

    getPercentageOfCompletion(item) {
      if (item.all_subtasks === 0) {
        return null;
      }

      if (item.all_subtasks) {
        return Math.round((item.done_subtasks / item.all_subtasks) * 100);
      }

      let done = item.checklists.reduce((acc, next) => {
        return acc + next.done_subtasks;
      }, 0);

      let all = item.checklists.reduce((acc, next) => {
        return acc + next.all_subtasks;
      }, 0);

      if (all === 0 && done === 0) {
        return 0;
      }

      let progress = Math.round((done * 100) / all);

      if (isNaN(progress)) {
        return null;
      }

      return progress;
    },

    formatDate(date) {
      if (date) return this.$moment(date).format("YYYY-MM-DD");
    },
    submitSearch(event) {
      this.page = 1;
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: this.page,
          search: this.search,
        },
      });
      this.$emit("reload", { search: this.search, page: this.page });
      return false;
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
    previewItem(item) {
      this.$emit("details", item);
    },
    newItem() {
      this.$emit("new", {});
    },
  },
};
</script>

<style lang="scss">
.delete--hover {
  &:hover {
    color: red;
  }
}

.preview--hover {
  &:hover {
    color: var(--primary);
  }
}

.edit--hover {
  &:hover {
    color: var(--primary);
  }
}
</style>
