var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"text-overflow",class:{ 'elevation-1': !_vm.bookings, 'my-10': !_vm.bookings },attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"hide-default-footer":"","items-per-page":24},on:{"click:row":_vm.previewItem},scopedSlots:_vm._u([(!_vm.bookings)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"70"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"ml-1",attrs:{"justify":"start"}},[_c('v-toolbar-title',{staticClass:"headline",attrs:{"data-cy":_vm.title}},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","single-line":"","hide-details":"","color":"primary"},on:{"input":_vm.submitSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticClass:"mr-1",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","text":""},on:{"click":function($event){return _vm.newItem()}}},[_vm._v("Add new ")])],1)],1)],1)]},proxy:true}:null,{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.uuid.slice(28, item.uuid.length))+" ")])]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.source.toLowerCase())+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description !== null && item.description.length > 50)?_c('span',[_vm._v(_vm._s(item.description.slice(0, 50).concat("..."))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(item.due_date))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'COMPLETED')?_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getPercentageOfCompletionIcon(item).icon))])],1):_vm._e(),(item.status !== 'COMPLETED')?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getPercentageOfCompletionIcon(item).icon))])],1)]}}],null,true)},[(_vm.getPercentageOfCompletion(item) !== null)?_c('span',[(item.status === 'IN_PROGRESS')?_c('span',[_vm._v(" In Progress: "+_vm._s(_vm.getPercentageOfCompletion(item))+" % ")]):_c('span',[_vm._v(" Completed: "+_vm._s(_vm.getPercentageOfCompletion(item))+" % ")])]):_c('span',[_vm._v(" Cannot be calculated ")])])],1):_vm._e()]}},{key:"item.crm_boat",fn:function(ref){
var item = ref.item;
return [(item.crm_boat)?_c('span',[_vm._v(_vm._s(item.crm_boat.model.brand_name)+" "+_vm._s(item.crm_boat.model.model))]):_vm._e()]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"length":"4","readonly":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-icon',{attrs:{"color":props.isFilled ? 'primary' : ''},domProps:{"textContent":_vm._s(("mdi-numeric-" + (props.index + 1) + "-box"))}})]}}],null,true),model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}}],null,true)}),(!_vm.bookings)?_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":7,"color":"primary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }